<template>
  <div class="container withTop">
    <section class="contents cultureinfo">
      <el-radio-group v-model="activeName">
        <el-radio-button label="시기별 재배정보"></el-radio-button>
        <el-radio-button label="병 피해"></el-radio-button>
        <el-radio-button label="해충 피해"></el-radio-button>
        <!-- <el-radio-button label="병해충 피해"></el-radio-button> -->
      </el-radio-group>
      <div v-if="activeName == '시기별 재배정보'">
        <article class="listWrap box">
          <ul>
            <router-link
              to
              @click.native="handleMove(data._id, 'cultureinfo')"
              tag="li"
              class="flexB"
              v-for="(data, i) in cultureinfoList"
              :key="i"
            >
              <div>
                <p>{{ data.title }}</p>
                <span>{{ data.content_1 }}</span>
              </div>
              <span class="material-icons-round"> arrow_forward_ios </span>
            </router-link>
          </ul>
        </article>
        <Nodata v-if="cultureinfoList.length == 0" />
      </div>
      <div v-if="activeName == '병 피해'">
        <article class="listWrap box">
          <ul class="dbyhsList">
            <router-link
              to
              @click.native="handleMove(data.detailUrl._text, 'sickKey')"
              tag="li"
              class="flexB"
              v-for="(data, i) in dbyhsList.filter(
                (item) => item.divName._text == '병'
              )"
              :key="i"
            >
              <div class="flex">
                <img
                  :src="`https://${data.thumbImg._text.substr(7)}`"
                  decoding="async"
                />
                <p>{{ data.korName._text }}</p>
              </div>
              <span class="material-icons-round"> arrow_forward_ios </span>
            </router-link>
          </ul>
        </article>
        <Nodata
          v-if="
            dbyhsList.filter((item) => item.divName._text == '병').length == 0
          "
        />
      </div>
      <div v-if="activeName == '해충 피해'">
        <article class="listWrap box" v-if="activeName == '해충 피해'">
          <ul class="dbyhsList">
            <router-link
              to
              @click.native="handleMove(data.detailUrl._text, 'insectKey')"
              tag="li"
              class="flexB"
              v-for="(data, i) in dbyhsList.filter(
                (item) => item.divName._text == '해충'
              )"
              :key="i"
            >
              <div class="flex">
                <img
                  :src="`https://${data.thumbImg._text.substr(7)}`"
                  decoding="async"
                />
                <p>{{ data.korName._text }}</p>
              </div>
              <span class="material-icons-round"> arrow_forward_ios </span>
            </router-link>
          </ul>
        </article>
        <Nodata
          v-if="
            dbyhsList.filter((item) => item.divName._text == '해충').length == 0
          "
        />
      </div>
      <!-- <div v-if="activeName == '병해충 피해'" class="iframeWrap">
        <article class="box" v-if="activeName == '병해충 피해'">
          <iframe src="https://ncpms.rda.go.kr/mobile/MobileImageSrchListR.ms?hiKncrCode=FT&sKncrCode=FT010601"> </iframe>
        </article>
      </div> -->
    </section>
  </div>
</template>
<script>
import { fetchCultureInfoList, fetchDbyhsByNcpms } from "@/api/cultureinfo";
import Nodata from "../../components/contents/Nodata.vue";

export default {
  components: { Nodata },

  data() {
    return {
      activeName: "시기별 재배정보",
      category: "",
      startPoint: 1,
      total: 0,
      dbyhsList: [],
      cultureinfoList: [],
      dbyhsList1: [],
      dbyhsList2: [],
      dbyhsList3: [],
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 2);
    this.$store.dispatch("SET_MENU_VISIBLE", true);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", true);
    this.$store.dispatch("SET_PAGE_NAME", "작물재배정보");
  },
  mounted() {
    if (this.$route.query.category) {
      this.category = this.$route.query.category;
      this.getCultureInfoList();
      this.getDbyhsList();
    }
  },
  methods: {
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    getDbyhsList() {
      let params = {
        cropName: this.category,
        startPoint: this.startPoint,
      };
      fetchDbyhsByNcpms(params).then((res) => {
        console.log("res", res);
        if (res.status == 200) {
          if (res.data.data.service.totalCount._text == 0) {
            return;
          }
          let tempList = this.dbyhsList;
          this.dbyhsList = tempList.concat(res.data.data.service.list.item);
          this.total = Number(res.data.data.service.totalCount._text);
          this.startPoint = this.dbyhsList.length + 1;
          tempList = [];

          if (this.dbyhsList.length != this.total) {
            this.handleMore();
          }
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
    handleMore() {
      this.getDbyhsList();
    },
    handleMove(data, type) {
      let param = new URLSearchParams(data);
      let key = "";
      let id = "";
      let serviceCode = "";
      if (type == "insectKey") {
        key = param.get("insectKey");
        serviceCode = param.get("serviceCode");
        this.$router.replace({
          name: "cultureInfoDetail",
          query: { key: key, serviceCode: serviceCode },
        });
      } else if (type == "sickKey") {
        key = param.get("sickKey");
        serviceCode = param.get("serviceCode");
        this.$router.replace({
          name: "cultureInfoDetail",
          query: { key: key, serviceCode: serviceCode },
        });
      } else {
        id = data;
        this.$router.replace({ name: "cultureInfoDetail", query: { id: id } });
      }
    },
    getCultureInfoList() {
      let params = {
        category: this.category,
      };
      fetchCultureInfoList(params).then((res) => {
        if (res.status == 200) {
          this.cultureinfoList = res.data.data;
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
  },
};
</script>
